<template>
  <div>
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>알림 설정</h1>
        <p class="stit">새로운 근무에 대한 알림을 설정할 수 있습니다.</p>
      </div>
      <div class="sub-cont">
        <div class="myp-form-wrap">
          <dl>
            <dt>알림설정</dt>
            <dd>
              <div class="form-item-wrap">
                <div class="label">근무요청 알림</div>
                <label>
                  <input
                    type="checkbox"
                    v-model="pushUseYn"
                    true-value="Y"
                    false-value="N"
                  />
                  <span>새로운 근무 지원 요청</span>
                </label>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="myp-btn-wrap">
      <button type="button" class="btn-save01" @click="save">수정</button>
    </div>
  </div>
</template>

<script>
import { getNotificationConfig, saveNotificationConfig } from 'Api/modules'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      pushUseYn: '',
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    async save() {
      const saveData = {
        userId: this.account.userId,
        pushUseYn: this.pushUseYn,
      }

      //입력 오류
      // if (!this.validateInput(saveData)) return false
      try {
        await saveNotificationConfig(saveData)
        if (this.pushUseYn === 'Y') {
          this.$toasted.show('설정되었습니다.')
        } else if (this.pushUseYn === 'N') {
          this.$toasted.show('해제되었습니다.')
        }
        this.isSaved = true
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    const res = await getNotificationConfig({ userId: this.account.userId })
    if (res) {
      this.pushUseYn = res.pushUseYn
    }
  },
}
</script>

<style></style>
